<!-- 审核弹窗弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'订单审核':'订单审核'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="150px">

      <el-form-item label="订单取消时间：">
        <span>2023-12-14 12:30:54</span>
      </el-form-item>
      <el-form-item label="订单取消人：">
        <span>刘雯</span>
      </el-form-item>
      <el-form-item label="是否超过15分钟：">
        <span>是</span>
      </el-form-item>
      <el-form-item label="取消是否收费：">
        <el-radio v-model="form.radio" label="1">是</el-radio>
        <el-radio v-model="form.radio" label="2">否</el-radio>
      </el-form-item>
      <el-form-item label="订单来源是否收费：" v-if="form.radio == '1'">
        <el-select
            clearable
            v-model="form.authentication_status"
            placeholder="请选择"
            class="ele-fluid">
          <el-option label="是" value="1"/>
          <el-option label="否" value="2"/>
        </el-select>
      </el-form-item>
      <el-form-item label="下游是否收费：" v-if="form.radio == '1'">
        <el-select
            clearable
            v-model="form.authentication_status"
            placeholder="请选择"
            class="ele-fluid">
          <el-option label="是" value="1"/>
          <el-option label="否" value="2"/>
        </el-select>
      </el-form-item>
      <el-form-item label="回访内容：">
        <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5}"
            placeholder="请输入对客户的回访内容及取消原因"
            v-model="form.textarea2">
        </el-input>
      </el-form-item>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

  }
}
</script>

<style scoped lang="scss">

</style>
